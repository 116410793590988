import { PUBLIC_ENVIRONMENT, PUBLIC_SENTRY_DSN } from '$env/static/public';
import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import './app.css';
import './desktop-app.css';
import { isDaylightDesktop, onDesktopWindowLoaded } from './lib/utils/desktop-app.js';

// Capture client errors
Sentry.init({
	dsn: PUBLIC_SENTRY_DSN,
	environment: PUBLIC_ENVIRONMENT,
	tracesSampleRate: 1.0
});

export const handleError = handleErrorWithSentry();

// Add desktop app styles
if (isDaylightDesktop) {
	// Always load desktop styles and enable via class for faster loading
	document.documentElement.classList.add('is-daylight-desktop');

	onDesktopWindowLoaded();
}
