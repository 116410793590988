import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17')
];

export const server_loads = [2,3];

export const dictionary = {
		"/(app)": [4,[2]],
		"/(app)/account": [~5,[2]],
		"/api/auth/done": [17],
		"/(public)/app": [~9,[3]],
		"/(app)/desktop-window-done": [6,[2]],
		"/(public)/extension-auth": [10,[3]],
		"/(public)/google-drive": [11,[3]],
		"/(app)/integrations": [~7,[2]],
		"/(app)/login-app": [8,[2]],
		"/(public)/login": [12,[3]],
		"/(public)/pdf": [~13,[3]],
		"/(public)/pdf/[slugId]": [~14,[3]],
		"/(public)/privacy": [15,[3]],
		"/(public)/support": [16,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';